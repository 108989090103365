import {Directive, HostListener, ElementRef, OnInit} from '@angular/core';

@Directive({
	selector: '[appCurrencyInput]'
})
export class CurrencyInputDirective implements OnInit {

	private el: HTMLInputElement;
	private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/); // Allows digits and up to two decimal places
	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight'];

	constructor(private elementRef: ElementRef) {
		this.el = this.elementRef.nativeElement;
	}

	ngOnInit() {
		this.el.value = this.formatValue(this.el.value);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return; // Allow navigation keypresses (arrow keys, delete, etc.)
		}

		const current: string = this.el.value;
		const next: string = current.concat(event.key);
		if (!String(next).match(this.regex)) {
			event.preventDefault(); // Prevent the keypress if it results in an invalid format
		}
	}

	@HostListener('blur', ['$event.target.value'])
	onBlur(value: string) {
		this.el.value = this.formatValue(value); // Format the value on blur
	}

	@HostListener('focus', ['$event.target.value'])
	onFocus(value: string) {
		this.el.value = this.unformatValue(value); // Unformat the value on focus
	}

	private unformatValue(value: string): string {
		return value.replace(/[^\d.-]/g, ''); // Remove everything except digits, a period, or a minus sign
	}

	private formatValue(value: string): string {
		const numericValue = parseFloat(value);
		if (!isNaN(numericValue)) {
			return numericValue.toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2
			});
		}
		return '';
	}
}
