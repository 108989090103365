import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';

import moment from 'moment';

import {AccountsReceivableService} from '../../_services/accounts-receivable.service';
import {
	ArCommissionStatusCreateRequest,
	ArCommissionStatusCreateRowRequest,
	ArCommissionStatusReport,
	ArCommissionStatusValidateRequest,
	ArCommissionStatusValidateRowRequest,
	ArCommissionStatusValidationError,
	ArVendor,
	ReservationResponse
} from '../../_models/accounts-receivable.model';
import {Loader} from '../../_models/loader';


@Component({
	selector: 'app-ar-reservation-report-create-our-numbers',
	templateUrl: './ar-reservation-report-create-our-numbers.component.html',
	styleUrls: ['./ar-reservation-report-create-our-numbers.component.scss']
})
export class ArReservationReportCreateOurNumbersComponent implements OnInit, AfterViewInit {

	fileContent: string | null = null;
	parsedData: ArCommissionStatusReport[];
	dataSourceFound = new MatTableDataSource<ReservationResponse>();
	dataSourceNotFound = new MatTableDataSource<ArCommissionStatusReport>();
	dataSourceDuplicates = new MatTableDataSource<ReservationResponse>();
	loader = new Loader();
	selectedReservations:  ReservationResponse[] = [];
	arVendors: ArVendor[] = [];
	selectedArVendorId = '';
	selectedArVendor: ArVendor;
	detailsVisible = false
	previewMode = 'validate';     // 'validate' | 'preview'
	totalAmountInvoiced = 0;
	totalAmountPaid = 0;
	processDate = new Date();
	createButtonEnabled = false;
	foundReservations: ReservationResponse[];
	notFoundReservationsConfCodes: string[];
	duplicateReservations: ReservationResponse[] = [];
	notFoundReservationsReportRows: ArCommissionStatusReport[];
	validationErrors: ArCommissionStatusValidationError[] = [];

	displayedColumnsFound: string[] = ['id', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'reasonCode'];

	displayedColumnsNotFound: string[] = ['confirmationCode', 'reasonCode' ];

	displayedColumnsDuplicates: string[] = ['confirmationCode', 'reservationId', 'brandId', 'brandName', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost'];

	constructor(private readonly accountsReceivableService: AccountsReceivableService, private readonly router: Router,
				         private readonly snackBar: MatSnackBar) {
	}

	ngOnInit() {
		console.log('ArReservationReportCreateComponent initialized');
		this.loader.isLoading = false;
	}

	ngAfterViewInit() {
		this.accountsReceivableService.getAllArVendors().subscribe({
			next: data => {
				this.arVendors = data.filter(v => v.commProviderId === null && [1, 2, 5, 6, 7].includes(v.syndicationTypeId));
			}
		})
	}

	createPreviewFromData() {
		this.previewMode = 'preview';
	}

	validateStatusReport() {
		this.selectedArVendor = this.findVendorById(this.selectedArVendorId)
		this.loader.isLoading = true;
		const rows: ArCommissionStatusValidateRowRequest[] = [];
		for (const line of this.parsedData) {
			rows.push({
				confirm_num: line.confCode,
				reason_code: line.reasonCode,
				columns: [{
					revenue_type: 'BOOKING',
					amount: line.amountNewInvoice,
				},
				{
					revenue_type: 'PAYMENT',
					amount: line.amountNewPayment,
				}]
			})
		}
		const validationRequest: ArCommissionStatusValidateRequest = {
			process_date: moment(Date.now()).toDate(),
			ar_vendor_id: this.selectedArVendor.id,
			report_name: `STATUS UPLOAD ${this.selectedArVendor.name}-${new Date().toISOString()}`,
			adjustment_type: 'ADD',
			syndication_type_id: this.selectedArVendor.syndicationTypeId,
			lines: rows
		}
		this.accountsReceivableService.validateStatusReport(validationRequest).subscribe({
			next: response => {
				this.foundReservations = response.foundReservations;
				this.notFoundReservationsConfCodes = response.notFoundReservations;
				this.duplicateReservations = response.duplicateReservations;
				this.validationErrors = response.reservationValidationErrors;
				this.createValidationReport();
			},
			error: error => {
				this.loader.isLoading = false;
				console.log(error);
			}
		})
	}

	createValidationReport() {
		this.selectedReservations = this.foundReservations;

		const notFoundReportRows = this.parsedData.filter(row => this.notFoundReservationsConfCodes.includes(row.confCode));
		this.notFoundReservationsReportRows = notFoundReportRows;

		this.selectedArVendor = this.findVendorById(this.selectedArVendorId)
		this.detailsVisible = true;

		this.dataSourceFound.data = this.selectedReservations;
		this.dataSourceNotFound.data = notFoundReportRows;
		this.dataSourceDuplicates.data = this.duplicateReservations;

		this.loader.isLoading = false;
	}

	createStatusReport() {
		const rows: ArCommissionStatusCreateRowRequest[] = [];
		for (const reservation of this.selectedReservations) {
			rows.push({
				reservation_id: reservation.id,
				confirm_num: reservation.confirmationCode,
				reason_code: reservation.reasonCode,
				columns: [{
					revenue_type: 'BOOKING',
					amount: reservation.amountNewInvoice,
				}]
			})
		}
		const statusRequest: ArCommissionStatusCreateRequest = {
			process_date: moment(this.processDate).toDate(),
			ar_vendor_id: this.selectedArVendor.id,
			report_name: `${this.selectedArVendor.name}-${new Date().toISOString()}`,
			adjustment_type: 'ADD',
			syndication_type_id: this.selectedArVendor.syndicationTypeId,
			lines: rows
		}

		this.accountsReceivableService.createStatusReport(statusRequest).subscribe({
			next: (statusCode: number) => {
				this.loader.isLoading = false;
				if (statusCode === 200) {
					console.log('status report uploaded successfully');
					this.initializeData();
					this.openSnackBar('Successfully uploaded report', 'success');
				}
			}
		})
	}

	initializeData() {
		this.previewMode = 'validate';
		this.selectedArVendorId = '';
		this.parsedData = [];
		this.dataSourceFound.data = [];
		this.selectedReservations = [];
		this.totalAmountInvoiced = 0;
		this.totalAmountPaid = 0;
	}

	onBackButtonPressed() {
		this.detailsVisible = false;
		this.initializeData();
	}

	get showFileSelector() {
		return this.selectedArVendorId !== '';
	}

	get isCreatePreviewEnabled() {
		return this.selectedArVendorId !== '' && this.foundReservations.length > 0 &&
			this.duplicateReservations.length === 0 && this.validationErrors.length === 0;
	}

	get isCreateStatusUploadEnabled() {
		return this.createButtonEnabled || this.selectedReservations.length > 0;
	}

	findVendorById(id: string): ArVendor | undefined {
		return this.arVendors.find(vendor => vendor.id === +id);
	}

	// file select and drag & drop
	onDragOver(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(true);
	}

	onDragLeave(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(false);
	}

	onDrop(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(false);

		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			const file = event.dataTransfer.files[0];

			if (file.type === 'text/csv') {
				this.readFile(file);
			} else {
				alert('Please drop a valid CSV file.');
			}
		} else {
			alert('No file detected. Please drop a valid file.');
		}
	}

	onFileSelect(event: Event): void {
		const input = event.target as HTMLInputElement;
		const file = input.files && input.files[0];
		if (file) {
			this.readFile(file);
		}
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 5000,
		});
	}

	private setDragAreaStyle(isDragging: boolean): void {
		const dragArea = document.querySelector('.drag-drop-area') as HTMLElement;
		if (isDragging) {
			dragArea.classList.add('dragging');
		} else {
			dragArea.classList.remove('dragging');
		}
	}

	private readFile(file: File): void {
		const reader = new FileReader();
		reader.onload = () => {
			const csvData = reader.result as string;
			this.fileContent = csvData;
			this.parsedData = this.parseCSV(csvData);
			this.validateStatusReport()
		};
		reader.onerror = () => {
			console.error('Error reading file');
		};
		reader.readAsText(file);
	}

	private parseCSV(csvData: string): ArCommissionStatusReport[] {
		const lines = csvData.split('\n').map(line => line.trim());
		const data = lines.slice(1);

		return data
		.filter(line => line)
		.map(line => {
			const values = line.split(',');

			return {
				confCode: values[0] ? values[0].trim() : '',
				reasonCode: values[1] ? values[1].trim() : '',
				amountNewInvoice: null,
				amountNewPayment: null,
			};
		});
	}
}
