import {Component, OnInit} from '@angular/core';
import {VendorXml} from '../../_models/vendorXml';
import {SessionDebugService} from '../../_services/session-debug-service';
import * as vkbeautify from 'vkbeautify';
import {ExcelService} from '../../_services/excel-service';
import {ReservationService} from '../../_services/reservation-service';
import {Loader} from '../../_models/loader';

@Component({
	selector: 'app-session-debug',
	templateUrl: './session-debug.component.html',
	styleUrls: ['./session-debug.component.scss'],
})

export class SessionDebugComponent implements OnInit {

	_sessionId: string;
	filterBy: string;
	vendorXmls: VendorXml[];
	errorMsg: string;
	sessionDebugService: SessionDebugService;
	reservationService: ReservationService;
	excelService: ExcelService;
	_displaySection: string;
	_textRequestContent: string;
	_textResponseContent: string;
	_tempUrl: string;
	_textRequestContentType: string;
	_textResponseContentType: string;
	_textContentRequestDisplay: string;
	_textContentResponseDisplay: string;
	userDetails: any[];
	reservationDetails: any[];
	debugEvents: any[];
	sessionDetails: any[];
	searchDetails: any[];
	clicks: any[];
	searchTerm: string;
	loader: Loader;
	showVendorXmlsError: boolean;
	sessionDetailsError: boolean;
	userDetailsError: boolean;
	reservationDetailsError: boolean;
	debugEventsError: boolean;
	searchDetailsError: boolean;
	clicksError: boolean;

	displayedColumnsSessionDetails: string[] = ['id', 'jsession_id', 'campaign_id', 'channel_id', 'partner_id',
		'latency_cid', 'marketing_group', 'request_time', 'is_bot', 'is_mobile', 'external_user_id', 'screen_size',
		'remote_ip', 'remote_dma', 'remote_zip', 'remote_country', 'remote_latitude', 'remote_longitude', 'referer',
		'user_agent', 'original_session_id', 'x_forwarded_for', 'web_session_campaign'];


	displayedColumnsUserDetails: string[] = ['id', 'email', 'under25', 'last_login', 'num_logins', 'first_name',
		'last_name', 'address1', 'address2', 'city', 'state', 'postal_code', 'country', 'phone', 'opt_in',
		'opt_in_date', 'opt_in_session_id', 'opt_out_date', 'opt_out_session_id', 'was_opt_in', 'remote_dma',
		'hotel_signup'];

	displayedColumnsReservations: string[] = ['id', 'rate_id', 'rate_reference_id', 'session_id', 'vendor_id',
		'brand_id', 'user_id', 'search_id', 'vendor_confirmation_code', 'brand_confirmation_code', 'reservation_date',
		'pickup_location_id', 'pickup_airport_code', 'dropoff_location_id', 'dropoff_airport_code', 'car_type',
		'car_class', 'car_size', 'image_url', 'large_bags', 'small_bags', 'large_passengers', 'small_passengers',
		'capacity', 'mileage_units', 'mileage_included', 'extra_mileage', 'car_model', 'has_ac', 'automatic',
		'is4_wd', 'has_four_doors', 'hourly_rate', 'hours', 'daily_rate', 'days', 'weekly_rate', 'weeks',
		'weekend_rate', 'weekend_days', 'extra_hour_rate', 'extra_hours', 'extra_day_rate', 'extra_days',
		'monthly_rate', 'months', 'total_days', 'cc_required', 'prepaid', 'sipp_code', 'pickup_date',
		'dropoff_date', 'is_special_rate', 'flight_required', 'unlimited_mileage', 'percent_discount',
		'flat_discount', 'promo_code', 'pretax_cost', 'discounted_pretax_cost', 'total_cost', 'total_cost_usd',
		'prepay_amount', 'prepay_percentage', 'currency', 'currency_conversion_factor', 'cancelled', 'cancel_time',
		'cancel_code', 'email', 'first_name', 'last_name', 'phone', 'commission', 'address1', 'address2', 'city',
		'state', 'postal_code', 'country', 'pickup_country', 'reminder_sent', 'survey_sent', 'partner_id',
		'campaign_id', 'channel_id', 'customer_confirmed_pickup', 'no_show', 'vendor_cancelled', 'extended',
		'reported', 'overbooked', 'airline', 'flight_number', 'cc_name', 'cc_type', 'cc_expiration_year',
		'cc_expiration_month', 'original_reservation_id', 'view_id', 'click_area', 'click_index', 'modified_by',
		'discount_cd', 'discount_rc', 'discount_pc', 'has_insurance', 'pickup_sms_sent', 'dropoff_sms_sent',
		'pretax_cost_usd'];

	displayedColumnsDebugEvents: string[] = ['id', 'session_id', 'search_id', 'vendor_id', 'brand_id', 'xml_id',
		'debug_event_descriptor_id', 'subject', 'created_at', 'start_time', 'end_time', 'duration', 'request',
		'response'];

	displayedColumnsSearchDetails: string[] = ['id', 'session_id', 'same_location', 'pickup_location',
		'dropoff_location', 'time_of_search', 'query_time', 'pickup_time', 'dropoff_time', 'num_results',
		'num_duplicates', 'lowest_daily_rate', 'lowest_daily_rate_vendor_id', 'lowest_daily_rate_brand_id',
		'lowest_weekly_rate', 'lowest_weekly_rate_vendor_id', 'lowest_weekly_rate_brand_id', 'lowest_total_rate',
		'lowest_total_rate_vendor_id', 'lowest_total_rate_brand_id', 'highest_total_rate_vendor_id',
		'first_position_vendor_id', 'first_position_brand_id', 'airport_search', 'one_way_search', 'remote_search'];

	displayedColumnsVendorXml: string[] = ['id', 'vendor_id', 'vendor_name', 'session_id', 'search_id', 'data_source',
		'event', 'event_time', 'reference_id', 'request', 'response'];

	displayedColumnsClickRecords: string[] = ['id', 'timestamp', 'impression_id', 'search_id', 'uuid', 'device_type',
		'app_type', 'placement_type', 'brand_id', 'sipp_code', 'ad_placement_id', 'revenue', 'adjusted_revenue',
		'revenue_type', 'revenue_comments', 'revenue_capped', 'col_number', 'row_number'];

	query = {
		'first_name': '',
		'last_name': '',
		'email': '',
		'reservation_id': '',
		'brand_name': '',
		'vendor_name': '',
		'vendor_confirmation': '',
		'brand_confirmation': '',
		'car_type': '',
		'is_cancelled': '',
		'reservation_date1': null,
		'reservation_date2': null,
		'pickup_date1': null,
		'pickup_date2': null,
		'dropoff_date1': null,
		'dropoff_date2': null,
		'uuid': ''
	};

	constructor(sessionDebugService: SessionDebugService, excelService: ExcelService, reservationService: ReservationService) {
		this.sessionDebugService = sessionDebugService;
		this.excelService = excelService;
		this.reservationService = reservationService;
		this.vendorXmls = [];
		this._displaySection = 'none';
		this._textRequestContent = '';
		this._textContentRequestDisplay = '';
		this._textContentResponseDisplay = '';

		this.userDetails = [];
		this.reservationDetails = [];
		this.debugEvents = [];
		this.sessionDetails = [];
		this.searchDetails = [];
		this.clicks = [];
		this.filterBy = 'JSessionId';
		this.searchTerm = '';
		this.showVendorXmlsError = false;
		this.sessionDetailsError = false;
		this.userDetailsError = false;
		this.reservationDetailsError = false;
		this.debugEventsError = false;
		this.searchDetailsError = false;
		this.clicksError = false;
	}

	ngOnInit() {
		this.loader = new Loader();
	}

	getVendorXmls(): void {
		if (this.checkForm(this.searchTerm)) {
			this.userDetails = [];
			this.reservationDetails = [];
			this.debugEvents = [];
			this.sessionDetails = [];
			this.searchDetails = [];
			this.vendorXmls = [];
			this.clicks = [];
			this.errorMsg = '';
			this.showVendorXmlsError = false;
			this.sessionDetailsError = false;
			this.userDetailsError = false;
			this.reservationDetailsError = false;
			this.debugEventsError = false;
			this.searchDetailsError = false;
			this.clicksError = false;

			if (this.filterBy == 'ReservationConfirmation' || this.filterBy == 'ReservationId') {
				this.reservationService
					.getReservations(this.getQuery(this.searchTerm))
					.subscribe(c => {
						if (c != null && c.content[0] != null) {
							this._sessionId = c.content[0].session_id;
							this.getSessionDetailsBySessionId();
							this.getVendorXmlDetailsBySessionId();
						} else {
							this.errorMsg = 'No records found';
						}
					}, err => {
						this.errorMsg = err;
					})
			} else if (this.filterBy == 'JSessionId') {
				this.sessionDebugService.getSessionDetailsFromJsessionId(this.searchTerm)
					.subscribe(res => {
						if (res != null) {
							this._sessionId = res.id;
							this.getSessionDetailsBySessionId();
							this.getVendorXmlDetailsBySessionId();
						} else {
							this.errorMsg = 'No records found';
						}
					}, err => {
						this.errorMsg = 'No records found';
					})
			} else {
				this._sessionId = this.searchTerm;
				this.getSessionDetailsBySessionId();
				this.getVendorXmlDetailsBySessionId();
			}
		}
	}

	getQuery(searchTerm): any {
		if (this.filterBy == 'ReservationConfirmation') {
			this.query.vendor_confirmation = searchTerm;
			this.query.brand_confirmation = searchTerm;
		} else if (this.filterBy == 'ReservationId') {
			this.query.reservation_id = searchTerm;
		}
		return this.query;
	}

	getSessionDetailsBySessionId(): void {
		if (this.checkForm(this._sessionId)) {
			this.loader.isLoading = true;
			this.sessionDebugService
				.getSessionResponse(this._sessionId)
				.subscribe(res => {
					if (res.session != null) {
						this.sessionDetails.push(res.session);
					} else {
						this.sessionDetailsError = true;
					}

					if (res.users != null) {
						this.userDetails.push(res.users);
					} else {
						this.userDetailsError = true;
					}

					if (res.reservation_list != null) {
						this.reservationDetails = res.reservation_list;
					} else {
						this.reservationDetailsError = true;
					}
					this.debugEvents = res.debug_events;
					if (this.debugEvents.length == 0) {
						this.debugEventsError = true;
					}
					this.searchDetails = res.search_list;
					if (this.searchDetails.length == 0) {
						this.searchDetailsError = true;
					}
					this.clicks = res.cliks;
					if (this.clicks.length == 0) {
						this.clicksError = true;
					}
					this.loader.isLoading = false;
				}, err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				})
		}
	}

	getVendorXmlDetailsBySessionId() {
		this.sessionDebugService
			.getVendorXmls(this._sessionId)
			.subscribe(c => {
				this.vendorXmls = c;
				this._displaySection = 'list';
				if (this.vendorXmls.length == 0) {
					this.showVendorXmlsError = true;
				}
			}, err => {
				this.errorMsg = err;
				this.loader.isLoading = false;
			});
	}

	checkForm(sessionId: string): boolean {
		if (!sessionId || sessionId.length === 0) {
			if (this.filterBy == 'ReservationConfirmation') {
				this.errorMsg = 'Please Enter a Reservation Confirmation number';
				return false;
			} else if (this.filterBy == 'SessionId') {
				this.errorMsg = 'Please Enter a Session ID';
				return false;
			} else {
				this.errorMsg = 'Please Enter a Reservation ID';
				return false;
			}
		}
		return true;
	}

	validForm(): boolean {
		if (!this._sessionId) {
			return false;
		}
		return true;
	}

	displayRequestContent(xmlId: number, datasource: string): void {
		this.sessionDebugService
			.getSingleVendorRequest(xmlId, datasource)
			.subscribe(c => {
				this._textRequestContent = c.request;
				this._textResponseContent = c.response;
				this._displaySection = 'content';
				this.checkRequestContentType();
				this.checkResponseContentType();
			});
	}

	saveVendorRequestResponse(): void {
		const blobData = new Blob(['Request :', '\n \n', this._textContentRequestDisplay, '\n \n \n', 'Response :', '\n \n', this._textContentResponseDisplay], {type: 'application/octet-stream'});

		if (this._tempUrl) {
			window.URL.revokeObjectURL(this._tempUrl);
		}

		this._tempUrl = window.URL.createObjectURL(blobData);
		window.open(this._tempUrl);
	}

	saveVendorResponse(): void {
		const blobData = new Blob([this._textResponseContent], {type: 'application/octet-stream'});

		if (this._tempUrl) {
			window.URL.revokeObjectURL(this._tempUrl);
		}

		this._tempUrl = window.URL.createObjectURL(blobData);
		window.open(this._tempUrl);
	}

	saveVendorRequest(): void {
		const blobData = new Blob([this._textRequestContent], {type: 'application/octet-stream'});

		if (this._tempUrl) {
			window.URL.revokeObjectURL(this._tempUrl);
		}

		this._tempUrl = window.URL.createObjectURL(blobData);
		window.open(this._tempUrl);
	}

	displayVendorXmlList(): void {
		this._displaySection = 'list';
	}

	private checkRequestContentType(): void {
		if (this._textRequestContent.startsWith('{') || this._textRequestContent.startsWith('[')) {
			this._textRequestContentType = 'json';
			this._textContentRequestDisplay = vkbeautify.json(this._textRequestContent);
		} else if (this._textRequestContent.startsWith('<')) {
			this._textRequestContentType = 'xml';
			this._textContentRequestDisplay = vkbeautify.xml(this._textRequestContent);
		} else {
			this._textRequestContentType = '';
			this._textContentRequestDisplay = this._textRequestContent;
		}
	}

	private checkResponseContentType(): void {
		if (this._textResponseContent.startsWith('{')) {
			this._textResponseContentType = 'json';
			this._textContentResponseDisplay = vkbeautify.json(this._textResponseContent);
		} else if (this._textResponseContent.startsWith('<')) {
			this._textResponseContentType = 'xml';
			this._textContentResponseDisplay = vkbeautify.xml(this._textResponseContent);
		} else {
			this._textResponseContentType = '';
			this._textContentResponseDisplay = this._textResponseContent;
		}
	}

	copyToClipBoard(item) {
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (item));
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
	}

	exportAsXLSX(data, fileName): void {
		this.excelService.exportAsExcelFile(data, fileName + '_' + this._sessionId);
	}
}
