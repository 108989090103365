import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

import {AccountsPayableService} from '../../_services/accounts-payable.service';
import {ApInvoiceResponse} from '../../_models/accounts-payable.model';
import {Loader} from '../../_models/loader';


@Component({
	selector: 'app-ap-invoice-request-list',
	templateUrl: './ap-invoice-request-list.component.html',
	styleUrls: ['./ap-invoice-request-list.component.scss']
})
export class ApInvoiceRequestListComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort, {static: false}) sort: MatSort;
	dataSource = new MatTableDataSource<ApInvoiceResponse>();
	loader = new Loader();
	filterPending = false;
	allInvoices: ApInvoiceResponse[] = [];
	pendingInvoices: ApInvoiceResponse[] = [];
	displayedColumns: string[] = ['id', 'invoiceDate', 'invoiceDueDate', 'apVendorId', 'apVendorName', 'totalAmount', 'status'];

	searchCriteria = {
		apVendorName: ''
	};

	constructor(private readonly accountsPayableService: AccountsPayableService,
				         private readonly router: Router) {
	}

	ngOnInit() {
		console.log('ngOnInit()');
	}

	ngAfterViewInit(): void {
		console.log('ngAfterViewInit()');
		this.accountsPayableService.getAllApInvoices(true).subscribe({
			next: apInvoices => {
				this.allInvoices = apInvoices;
				this.pendingInvoices = this.allInvoices.filter(invoice => invoice.status === 'PENDING');
				this.dataSource.data = this.filterPending ? this.pendingInvoices : this.allInvoices;
				this.dataSource.sort = this.sort;
				this.dataSource.filterPredicate = this.createFilter();
			}
		})
	}

	createNewInvoice() {
		console.log('Creating New Invoice');
		this.router.navigate(['/admin/ap-invoice-request-create']);
	}

	toggleFilterPending() {
		this.filterPending = !this.filterPending;
		if (this.filterPending) {
			this.dataSource.data = this.pendingInvoices;
		} else {
			this.dataSource.data = this.allInvoices;
		}
	}

	onRowClicked(row) {
		console.log('Row clicked:', row);
		this.router.navigate(['/admin/ap-invoice-request-detail/', row.id]);
	}

	filterChange() {
		this.dataSource.filter = JSON.stringify(this.searchCriteria);
	}

	private createFilter(): (data: ApInvoiceResponse, filter: string) => boolean {
		return (data: ApInvoiceResponse, filter: string) => {
			const criteria = JSON.parse(filter);

			// Filter for arVendor name
			const matchesApVendorName = criteria.apVendorName ?
				data.apVendorName.toLowerCase().includes(criteria.apVendorName.toLowerCase()) : true;

			return matchesApVendorName;
		}
	}
}
