import {Component, OnInit} from '@angular/core';
import {Loader} from '../../_models/loader';
import {CampaignGroupService} from '../../_services/campaign-group-service';
import {MatSnackBar} from '@angular/material';

@Component({
	selector: 'app-campaign-group',
	templateUrl: './campaign-group.component.html',
	styleUrls: ['./campaign-group.component.scss'],
})

export class CampaignGroupComponent implements OnInit {
	public loader: Loader;
	public errorMsg: string;
	campaignGroupService: CampaignGroupService;
	campaignGroups: any[];
	campaignGroup: any;
	campaignGroupEdit: any;
	showPopUP: boolean;
	showEditPopUP: boolean;
	campaignGroupCreated: boolean;
	campaignGroupEdited: boolean;
	displayedColumns: string[] = ['index', 'name', 'device', 'baseCpc', 'operation'];

	constructor(campaignGroupService: CampaignGroupService, public snackBar: MatSnackBar) {
		this.campaignGroupService = campaignGroupService;
		this.showPopUP = false;
		this.showEditPopUP = false;
		this.campaignGroupCreated = false;
		this.campaignGroupEdited = false;
	}

	ngOnInit() {
		this.loader = new Loader();
		this.campaignGroupInit();
		this.getAllDetails();
	}

	campaignGroupInit() {
		this.campaignGroup = {
			name: '',
			device: '',
			base_cpc: 0
		};

		this.campaignGroupEdit = {
			name: '',
			device: '',
			base_cpc: 0
		};
	}

	getAllDetails() {
		this.errorMsg = '';
		this.campaignGroupService.getCampaignGroups()
			.subscribe(
				res => {
					this.campaignGroups = res;
					if (this.campaignGroups.length == 0) {
						this.errorMsg = 'No campaign groups found';
					}
				}, err => {
					this.errorMsg = err;
				}
			)
	}

	showPopUp() {
		this.showPopUP = true;
		this.campaignGroupInit();
	}

	closePopUp() {
		this.showPopUP = false;
		this.campaignGroupInit();
		this.campaignGroupCreated = false;
	}

	saveCampaignGroup() {
		if (this.validateCampaignGroup()) {
			this.campaignGroupService.createNewCampaignGroup(this.campaignGroup)
				.subscribe(
					res => {
						if (res != null) {
							this.campaignGroup = res;
							this.campaignGroupCreated = true;
							this.getAllDetails();
						}
					}, err => {
						this.openSnackBar(err);
					}
				)
		} else {
			this.openSnackBar('Please fill out mandatory fields to create campaign group');
		}
	}

	validateCampaignGroup() {
		if (this.campaignGroup == null) {
			return false;
		} else {
			if (this.campaignGroup.name == null || this.campaignGroup.name.length === 0) {
				return false;
			}
			if (this.campaignGroup.device == null || this.campaignGroup.device.length === 0) {
				return false;
			}
		}
		return true;
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, '', {
			duration: 4000,
		});
	}

	showEdit(item) {
		this.showEditPopUP = true;
		this.campaignGroupService.getCampaignGroupDetails(item.id)
			.subscribe(
				res => {
					if (res != null) {
						this.campaignGroupEdit = res;
					}
				}, err => {
					this.openSnackBar(err);
				}
			)
	}

	closeEditPopUp() {
		this.showEditPopUP = false;
		this.campaignGroupEdit = {};
		this.campaignGroupEdited = false;
	}

	updateCampaignGroup() {
		const id: number = this.campaignGroupEdit.id;
		this.campaignGroupService.updateCampaignGroup(this.campaignGroupEdit, id)
			.subscribe(
				res => {
					if (res != null) {
						this.campaignGroupEdit = res;
						this.campaignGroupEdit.id = id;
						this.campaignGroupEdited = true;
						this.getAllDetails();
					}
				}, err => {
					this.openSnackBar(err);
				}
			)
	}
}
