import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';

import {AccountsPayableService} from '../../_services/accounts-payable.service';
import {
	ApInvoiceRequest,
	ApInvoiceRequestLine,
	ApVendor,
	ReservationResponse
} from '../../_models/accounts-payable.model';


@Component({
	selector: 'app-ap-invoice-request-create',
	templateUrl: './ap-invoice-request-create.component.html',
	styleUrls: ['./ap-invoice-request-create.component.scss']
})
export class ApInvoiceRequestCreateComponent implements OnInit, AfterViewInit {

	dataSourceMain = new MatTableDataSource<ReservationResponse>();
	dataSourcePreview = new MatTableDataSource<ReservationResponse>();
	selectAllChecked = false;
	selectedReservations: ReservationResponse[] = [];
	presentPreview = false
	apVendors: ApVendor[] = [];
	_selectedApVendorId = '';
	selectedApVendor: ApVendor;
	invoiceRequestTotal = 0;
	invoiceRequestDate = new Date();
	invoiceDueDate: Date;

	@ViewChild(MatSort, {static: false}) set contentMain(sort: MatSort) {
		this.dataSourceMain.sort = sort;
	}

	@ViewChild(MatSort, {static: false}) set contentPreview(sort: MatSort) {
		this.dataSourcePreview.sort = sort;
	}

	displayedColumnsMain: string[] = ['select', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'cancelled', 'glApNotInvoiced'];

	displayedColumnsPreview: string[] = ['brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'cancelled', 'glApNotInvoiced'];

	constructor(private readonly accountsPayableService: AccountsPayableService,
				         private readonly router: Router,
				         private readonly snackBar: MatSnackBar) {
	}

	ngOnInit(): void {
		console.log('ngOnInit');
		this.invoiceDueDate = this.calculateLastDayOfMonth(this.invoiceRequestDate);
	}

	ngAfterViewInit(): void {
		console.log('ngAfterViewInit');

		this.accountsPayableService.getAllApVendors().subscribe({
			next: data => {
				this.apVendors = data;
			}
		})
	}

	get selectedApVendorId() {
		return this._selectedApVendorId;
	}

	set selectedApVendorId(value: string) {
		if (this._selectedApVendorId !== value) {
			this._selectedApVendorId = value;

			console.log('vendor selected ' + this.selectedApVendorId);
			this.accountsPayableService.getUnInvoicedReservations(this._selectedApVendorId).subscribe({
				next: reservations => {
					reservations.sort((r1, r2) =>
						r1.dropoffDate.getTime() - r2.dropoffDate.getTime());
					this.dataSourceMain.data = reservations;
				},
				error: (error) => {
					console.error('Error: ', error);
					this.openSnackBar(error.error.message, 'Error')
				}
			})
		}
	}

	get isCreatePreviewDisabled(): boolean {
		return this.dataSourceMain.data.filter(res => res.selected).length === 0 || this.selectedApVendorId === '';
	}

	findVendorById(id: string): ApVendor | undefined {
		return this.apVendors.find(vendor => vendor.id === +id);
	}

	toggleSelectAll() {
		this.selectAllChecked = !this.selectAllChecked;
		const filteredData = this.dataSourceMain.filteredData;
		filteredData.forEach(row => row.selected = this.selectAllChecked);
		this.updateInvoiceTotal()
	}

	updateInvoiceTotal() {
		this.selectedReservations = this.dataSourceMain.data.filter(res => res.selected);
		this.invoiceRequestTotal = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.glApNotInvoiced, 0);
		this.selectAllChecked = this.dataSourceMain.data.length === this.selectedReservations.length;
	}

	createPreviewFromSelection() {
		console.log('selected ap vendor: ' + this.selectedApVendorId);
		this.selectedReservations = this.dataSourceMain.data.filter(res => res.selected);

		this.invoiceRequestTotal = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.glApNotInvoiced, 0);

		this.selectedApVendor = this.findVendorById(this.selectedApVendorId);

		this.presentPreview = true;
		this.dataSourcePreview.data = this.selectedReservations;
	}

	onBackButtonPressed() {
		this.presentPreview = false;
	}

	createInvoiceRequestFromPreview() {
		console.log('createInvoiceRequestFromPreview');

		const lines: ApInvoiceRequestLine[] = [];
		for (const reservation of this.selectedReservations) {
			lines.push({
				reservation_id: reservation.id,
				amount: reservation.glApNotInvoiced,
				campaign_id: '11006'
			})
		}
		const invoiceRequest: ApInvoiceRequest = {
			invoice_date: this.invoiceRequestDate,
			invoice_due_date: this.invoiceDueDate,
			ap_vendor_id: +this.selectedApVendorId,
			ap_invoice_request_id: null,
			lines: lines
		};

		this.accountsPayableService.createApInvoice(true, invoiceRequest)
		.subscribe((response) => {
			console.log('HTTP Status Code:', response.statusCode)
			if (response.statusCode === 200) {
				console.log('Invoice was created');
				this.router.navigate([`/admin/ap-invoice-request-list`]);
			}
		},         (error) => {
			console.log(error);
		});

	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 5000,
		});
	}

	onDropoffMonthChanged(event: Date) {
		this.dataSourceMain.data.forEach(r => r.selected = this.dropoffMonthMatches(event, r));
		this.updateInvoiceTotal()
	}

	calculateLastDayOfMonth(date: Date): Date {
		return new Date(date.getFullYear(), date.getMonth() + 1, 0);
	}

	private dropoffMonthMatches(dropoffMonth: Date, reservation: ReservationResponse): boolean {
		const reservationDropoffMonthFirstDay = new Date(reservation.dropoffDate.getFullYear(), reservation.dropoffDate.getMonth(), 1);
		return reservationDropoffMonthFirstDay <= dropoffMonth;
	}
}
