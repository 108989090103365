import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import moment from 'moment';
import {AppConfigService} from './app-config.service';

import {
	ApInvoiceCreateResponse,
	ApInvoiceRequest,
	ApInvoiceResponse,
	ApPaymentRequest,
	ApVendor,
	InvoiceDetails,
	ReservationResponse
} from '../_models/accounts-payable.model';


@Injectable({providedIn: 'root'})
export class AccountsPayableService {
	private readonly baseUrl: string;

	constructor(private readonly httpClient: HttpClient,
				         private readonly authService: AuthService,
				         private readonly environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/accounting/payables';
	}

	getAllApInvoices(isRequestOnly: boolean): Observable<ApInvoiceResponse[]> {
		return this.httpClient.get<ApInvoiceResponse[]>(`${this.baseUrl}/invoices${isRequestOnly ? '/request' : ''}`,
			                                               this.authService.getOptions())
			.pipe(
				map((responses: any[]) => {
					return responses.map(response => {
						return {
							id: response.id,
							invoiceDate: response.invoice_date,
							invoiceDueDate: isRequestOnly ? response.invoice_due_date : null,
							apVendorId: response.ap_vendor_id,
							apVendorName: response.ap_vendor_name,
							apInvoiceRequestId: isRequestOnly ? null : response.ap_invoice_request_id,
							totalAmount: response.total_amount,
							amountPaid: response.amount_paid,
							bankCharges: 0, // response.bank_charges
							status: isRequestOnly ? response.status : null
						}
					})
				})
			);
	}

	createApInvoice(isRequestOnly: boolean, data: ApInvoiceRequest): Observable<{ statusCode: number; invoice: ApInvoiceCreateResponse }> {
		return this.httpClient
		.post(`${this.baseUrl}/invoices${isRequestOnly ? '/request' : ''}`, JSON.stringify(data), {
			...this.authService.getOptions(),
			observe: 'response',
		})
		.pipe(
			map((response: any) => ({
				statusCode: response.status,
				invoice: {
					apInvoiceId: response.body.ap_invoice_id,
					apVendorId: response.body.ap_vendor_id,
					totalAmount: response.body.total_amount,
					invoiceDate: moment(response.body.invoice_date, 'YYYY-MM-DD').toDate(),
					invoiceDueDate: moment(response.body.invoice_due_date, 'YYYY-MM-DD').toDate()
				},
			}))
		);
	}

	createApPayment(data: ApPaymentRequest): Observable<number> {
		return this.httpClient.post(
			`${this.baseUrl}/invoices/reservations/payment`,
			JSON.stringify(data),
			{
				...this.authService.getOptions(),
				observe: 'response'
			})
		.pipe(map(res => res.status));
	}

	getUnInvoicedReservations(apVendorId: string): Observable<ReservationResponse[]> {
		const headers = this.authService.getOptions()
		const httpOptions = {
			headers: headers.headers,
			params: {'apVendorId': apVendorId}
		};

		return this.httpClient.get<ReservationResponse[]>(`${this.baseUrl}/reservations/uninvoiced`, httpOptions)
		.pipe(
			map((responses: any[]) => {
				return responses.map(response => {
					return {
						id: response.id,
						brandId: response.brand_id,
						brandName: response.brand_name,
						confirmationCode: response.confirmation_code,
						reservationDate: moment(response.reservation_date, 'YYYY-MM-DD').toDate(),
						pickupDate: moment(response.pickup_date, 'YYYY-MM-DD').toDate(),
						dropoffDate: moment(response.dropoff_date, 'YYYY-MM-DD').toDate(),
						pretaxCost: response.pretax_cost,
						totalCost: response.total_cost,
						currencyCode: response.currency,
						cancelled: response.cancelled,
						commission: response.commission,
						estimatedCommission: response.estimated_commission,
						glApNotInvoiced: response.gl_ap_invoiced_not_paid,
						gl_ap_invoice_not_paid: response.gl_ap_invoiced_not_paid,
						reported: response.reported,
						amountInvoiced: null,
						amountPaid: null,
						reasonCode: null,
						amountNewInvoice: null,
						amountNewPayment: null
					}
				});
			}));
	}

	getAllApVendors(): Observable<ApVendor[]> {
		return this.httpClient.get<ApVendor[]>(`${this.baseUrl}/vendors`, this.authService.getOptions())
		.pipe(
			map((responses: any[]) => {
				return responses.map(response => {
					return {
						id: response.id,
						name: response.name,
					}
				})
			})
		)
	}

	getInvoiceDetails(isRequestOnly: boolean, id: number): Observable<InvoiceDetails> {
		console.log('getInvoiceDetails:', id);
		return this.httpClient.get<InvoiceDetails>(`${this.baseUrl}/invoices/${isRequestOnly ? 'request/' : ''}${id}`,
			                                          this.authService.getOptions())
		.pipe(
			map((invoice: any) => {
					console.log('getInvoiceDetails:', invoice);
					return {
						id: invoice.id,
						invoiceDate: moment(invoice.invoice_date, 'YYYY-MM-DD').toDate(),
						invoiceDueDate: isRequestOnly ? moment(invoice.invoice_due_date, 'YYYY-MM-DD').toDate() : null,
						apVendorId: invoice.ap_vendor_id,
						apVendorName: invoice.ap_vendor_name,
						apInvoiceRequestId: isRequestOnly ? null : invoice.ap_invoice_request_id,
						amountTotal: invoice.amount_total,
						amountPaid: invoice.amount_paid,
						bankCharges: invoice.bank_charges,
						status: isRequestOnly ? invoice.status : null,
						reservations: invoice.reservations.map((reservation: any) => {
							return {
								reservationId: reservation.reservation_id,
								brandId: reservation.brand_id,
								brandName: reservation.brand_name,
								amountPaid: reservation.amount_paid,
								reservationDate: moment(reservation.reservation_date),
								confirmationCode: reservation.confirmation_code,
								pickupDate: moment(reservation.pickup_date),
								dropoffDate: moment(reservation.dropoff_date),
								pretaxCost: reservation.pretax_cost,
								totalCost: reservation.total_cost,
								amountInvoiced: reservation.amount_invoiced,
								amountUnpaid: reservation.amount_invoiced - reservation.amount_paid,
								currencyCode: reservation.currency
							}
						}),
						payments: invoice.payments.map((payment: any) => {
							return {
								id: payment.id,
								apVendorId: payment.ap_vendor_id,
								paymentDate: moment(payment.payment_date, 'YYYY-MM-DD'),
								totalAmount: payment.total_amount
							}
						})
					}
				}
			)
		);
	}

	cancelApInvoiceRequest(id: number): Observable<any> {
		console.log('cancelApInvoiceRequest:', id);
		return this.httpClient.delete<InvoiceDetails>(`${this.baseUrl}/invoices/request/${id}`, this.authService.getOptions());
	}
}
