import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSort, MatTableDataSource} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';

import moment from 'moment';

import {ExcelService} from '../../_services/excel-service';
import {AccountsPayableService} from '../../_services/accounts-payable.service';
import {Loader} from '../../_models/loader';
import {
	InvoiceDetails,
	InvoiceDetailsReservation
} from '../../_models/accounts-payable.model';


@Component({
	selector: 'app-ap-invoice-request-detail',
	templateUrl: './ap-invoice-request-detail.component.html',
	styleUrls: ['./ap-invoice-request-detail.component.scss']
})
export class ApInvoiceRequestDetailComponent implements OnInit, AfterViewInit {

	dataSourceReservations = new MatTableDataSource<InvoiceDetailsReservation>();

	loader = new Loader();
	invoiceId = 0;
	invoiceDetail: InvoiceDetails = {
		id: 0,
		apVendorName: '',
		apVendorId: 1,
		apInvoiceRequestId: 0,
		invoiceDate: new Date(),
		invoiceDueDate: null,
		amountPaid: 0,
		amountTotal: 0,
		bankCharges: 0,
		reservations: [],
		payments: [],
		status: ''
	}

	displayedColumnsReservations: string[] = ['reservationId', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'amountInvoiced'];

	@ViewChild('sortReservations', {static: false}) sortReservations!: MatSort;

	constructor(private readonly accountsPayableService: AccountsPayableService,
				         private readonly router: Router,
				         private readonly snackBar: MatSnackBar,
				         private readonly route: ActivatedRoute,
				         private readonly location: Location,
				         private readonly excelService: ExcelService) {
	}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			const id = params.get('id');
			this.invoiceId = id ? +id : null;
			console.log('Invoice ID:', this.invoiceId);
		});
		this.loader.isLoading = false;
	}

	ngAfterViewInit() {
		console.log('afterViewInit');
		this.accountsPayableService.getInvoiceDetails(true, this.invoiceId).subscribe({
			next: invoice => {
				this.loader.isLoading = false;
				this.invoiceDetail = invoice;
				this.dataSourceReservations.data = invoice.reservations;
			}
		})
		this.dataSourceReservations.sort = this.sortReservations;
	}

	onCancelInvoiceRequest() {
		console.log('cancel invoice request')
		this.accountsPayableService.cancelApInvoiceRequest(this.invoiceId).subscribe({
				next: value => {
					this.openSnackBar(`Invoice Request ID ${this.invoiceId} cancelled`, 'Success', 3000)
				},
				error: err => {
					console.log(err)
					this.openSnackBar(err.message, 'Error', 3000)
				}
			}
		)
	}

	onBackButtonPressed() {
		this.location.back()
	}

	openSnackBar(message: string, type: string, duration: number) {
		this.snackBar.open(message, type, {
			duration: duration,
		});
	}

	get isCancelButtonEnabled(): boolean {
		return  this.invoiceDetail.status === 'PENDING';
	}

	exportAsXlsx(): void {
		const data = this.dataSourceReservations.data;
		const fileName = `INVOICE-REQUEST-${this.invoiceDetail.apVendorName}-${new Date().toISOString()}`;
		this.excelService.exportAsExcelFile(data.map(reservation => {
				return {
					id: reservation.reservationId,
					brand_id: reservation.brandId,
					brand_name: reservation.brandName,
					confirmation_code: reservation.confirmationCode,
					reservation_date: moment(reservation.reservationDate).format('MM/DD/YYYY'),
					pickup_date: moment(reservation.pickupDate).format('MM/DD/YYYY'),
					dropoff_date: moment(reservation.dropoffDate).format('MM/DD/YYYY'),
					pretax_cost: reservation.pretaxCost,
					total_cost: reservation.totalCost,
					currency_code: reservation.currencyCode,
					invoice_amount_usd: reservation.amountInvoiced,
				}
			}),
			                                   fileName);
	}
}
