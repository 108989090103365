import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material';

import moment from 'moment';

import {AccountsReceivableService} from '../../_services/accounts-receivable.service';
import {
	ArCommissionStatusCreateRequest,
	ArCommissionStatusCreateRowRequest,
	ArCommissionStatusReport,
	ArCommissionStatusValidateRequest,
	ArCommissionStatusValidateRowRequest,
	ArCommissionStatusValidationError,
	ArInvoiceCreateResponse,
	ArInvoiceRequest,
	ArInvoiceRequestLine,
	ArPaymentRequestLine,
	ArRecordBankChargesRequest,
	ArRecordBankChargesResponse,
	ArRecordMiscRevenueLineRequest,
	ArRecordMiscRevenueRequest,
	ArRecordMiscRevenueResponse,
	ArVendor,
	ReservationResponse
} from '../../_models/accounts-receivable.model';
import {Loader} from '../../_models/loader';


@Component({
	selector: 'app-ar-reservation-report-create',
	templateUrl: './ar-reservation-report-create.component.html',
	styleUrls: ['./ar-reservation-report-create.component.scss']
})
export class ArReservationReportCreateComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort, {static: false}) set contentFound(sort: MatSort) {
		this.dataSourceFound.sort = sort;
	}

	@ViewChild(MatSort, {static: false}) set contentNotFound(sort: MatSort) {
		this.dataSourceNotFound.sort = sort;
	}

	@ViewChild(MatSort, {static: false}) set contentDuplicates(sort: MatSort) {
		this.dataSourceDuplicates.sort = sort;
	}

	loader = new Loader();
	dataSourceFound = new MatTableDataSource<ReservationResponse>();
	dataSourceNotFound = new MatTableDataSource<ArCommissionStatusReport>();
	dataSourceDuplicates = new MatTableDataSource<ReservationResponse>();

	fileContent: string | null = null;
	parsedData: ArCommissionStatusReport[];
	paymentDate: any;
	invoiceDate: any;
	detailsVisible = false
	previewMode = 'validate';     // 'validate' | 'preview'
	arVendors: ArVendor[] = [];
	selectedArVendorId = '';
	selectedArVendor: ArVendor;
	totalAmountInvoiced = 0;
	totalAmountPaid = 0;
	bankChargesAmountInput = '$0.00';
	createdInvoice: ArInvoiceCreateResponse;
	createButtonEnabled = false;
	selectedReservations:  ReservationResponse[] = [];
	foundReservations: ReservationResponse[];
	notFoundReservationsConfCodes: string[];
	duplicateReservations: ReservationResponse[] = [];
	notFoundReservationsReportRows: ArCommissionStatusReport[];
	validationErrors: ArCommissionStatusValidationError[] = [];
	miscBookingRevenueResult: ArRecordMiscRevenueResponse[] = [];
	bankChargesResult: ArRecordBankChargesResponse;

	displayedColumnsFound: string[] = ['id', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'amountInvoiced', 'amountPaid', 'amountNewInvoice',
		'amountNewPayment', 'reasonCode'];

	displayedColumnsNotFound: string[] = ['confirmationCode', 'amountNewInvoice', 'amountNewPayment', 'reasonCode' ];

	displayedColumnsDuplicates: string[] = ['confirmationCode', 'reservationId', 'brandId', 'brandName', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost'];

	constructor(private readonly accountsReceivableService: AccountsReceivableService, private readonly router: Router,
				         private readonly snackBar: MatSnackBar) {
	}

	ngOnInit() {
		console.log('ArReservationReportCreateComponent initialized');
	}

	ngAfterViewInit() {
		this.accountsReceivableService.getAllArVendors().subscribe({
			next: data => {
				this.arVendors = data.filter(v => v.commProviderId !== null && [3, 4].includes(v.syndicationTypeId));
			}
		})
	}

	createValidationReport() {
		this.selectedReservations = this.foundReservations;

		const notFoundReportRows = this.parsedData.filter(row => this.notFoundReservationsConfCodes.includes(row.confCode));
		this.notFoundReservationsReportRows = notFoundReportRows;

		this.selectedArVendor = this.findVendorById(this.selectedArVendorId)
		this.detailsVisible = true;

		this.dataSourceFound.data = this.selectedReservations;
		this.dataSourceNotFound.data = notFoundReportRows;
		this.dataSourceDuplicates.data = this.duplicateReservations;

		this.updateTotalInvoiceAmount();
		this.updateTotalPaymentAmount();
		this.loader.isLoading = false;
	}

	createPreviewFromData() {
		this.previewMode = 'preview';
		this.notFoundReservationsReportRows = this.notFoundReservationsReportRows.filter(row => row.amountNewInvoice !== 0)
		this.dataSourceNotFound.data = this.notFoundReservationsReportRows;
	}

	validateStatusReport() {
		this.selectedArVendor = this.findVendorById(this.selectedArVendorId)
		this.loader.isLoading = true;
		const rows: ArCommissionStatusValidateRowRequest[] = [];
		for (const line of this.parsedData) {
			rows.push({
				confirm_num: line.confCode,
				reason_code: line.reasonCode,
				columns: [{
					revenue_type: 'BOOKING',
					amount: line.amountNewInvoice,
				},
				{
					revenue_type: 'PAYMENT',
					amount: line.amountNewPayment,
				}]
			})
		}
		const validationRequest: ArCommissionStatusValidateRequest = {
			process_date: moment(this.invoiceDate).toDate(),
			ar_vendor_id: this.selectedArVendor.id,
			report_name: `STATUS UPLOAD ${this.selectedArVendor.name}-${new Date().toISOString()}`,
			adjustment_type: 'ADD',
			syndication_type_id: this.selectedArVendor.syndicationTypeId,
			lines: rows
		}
		this.accountsReceivableService.validateStatusReport(validationRequest).subscribe({
			next: response => {
				this.foundReservations = response.foundReservations;
				this.notFoundReservationsConfCodes = response.notFoundReservations;
				this.duplicateReservations = response.duplicateReservations;
				this.validationErrors = response.reservationValidationErrors;
				this.createValidationReport();
			},
			error: error => {
				this.loader.isLoading = false;
				console.log(error);
			}
		})
	}

	recordBankChargeExpenses() {
		this.loader.isLoading = true;
		if (this.bankChargeAmount > 0) {
			const request: ArRecordBankChargesRequest = {
				post_date: new Date(),
				ar_vendor_id: this.selectedArVendor.id,
				ar_vendor_name: this.selectedArVendor.name,
				amount: this.bankChargeAmount,
				expense_type: 'BANK CHARGES'
			}

			this.accountsReceivableService.createBankChargesExpense(request).subscribe({
				next: response => {
					this.bankChargesResult = response;
					this.recordMiscBookingRevenue();
				},
				error: error => {
					this.loader.isLoading = false;
					console.log(error);
				}
			})
		} else {
			this.recordMiscBookingRevenue();
		}
	}

	recordMiscBookingRevenue() {
		const lines: ArRecordMiscRevenueLineRequest[] = []
		this.notFoundReservationsReportRows.forEach(r => {
			lines.push({
				confirm_num: r.confCode,
				amount: r.amountNewInvoice
			})
		})

		const request: ArRecordMiscRevenueRequest = {
			post_date: new Date(),
			ar_vendor_id: this.selectedArVendor.id,
			ar_vendor_name: this.selectedArVendor.name,
			lines: lines
		}

		if (request.lines.length === 0) {
			this.createStatusReport();
		} else {
			this.accountsReceivableService.createMiscBookingRevenue(request).subscribe({
			next: response => {
				this.miscBookingRevenueResult = response;
				this.createStatusReport();
			},
			error: error => {
				this.loader.isLoading = false;
				console.log(error);
			}
		})
		}
	}

	createStatusReport() {
		const rows: ArCommissionStatusCreateRowRequest[] = [];
		for (const reservation of this.selectedReservations) {
			rows.push({
				reservation_id: reservation.id,
				confirm_num: reservation.confirmationCode,
				reason_code: reservation.reasonCode,
				columns: [{
					revenue_type: 'BOOKING',
					amount: reservation.amountNewInvoice,
				}]
			})
		}
		const statusRequest: ArCommissionStatusCreateRequest = {
			process_date: moment(this.invoiceDate).toDate(),
			ar_vendor_id: this.selectedArVendor.id,
			report_name: `${this.selectedArVendor.name}-${new Date().toISOString()}`,
			adjustment_type: 'ADD',
			syndication_type_id: this.selectedArVendor.syndicationTypeId,
			lines: rows
		}

		this.accountsReceivableService.createStatusReport(statusRequest).subscribe({
			next: (statusCode: number) => {
				if (statusCode === 200) {
					this.createInvoiceFromPreview()
				}
			},
			error: error => {
				this.loader.isLoading = false;
				console.log(error);
			}
		})
	}

	createInvoiceFromPreview() {
		const lines: ArInvoiceRequestLine[] = [];

		for (const reservation of this.selectedReservations) {
			lines.push({
				reservation_id: reservation.id,
				pl_adjustment_import_id: null,
				amount: reservation.amountNewInvoice,
				status: reservation.reasonCode
			})
		}
		for (const row of this.miscBookingRevenueResult) {
			lines.push({
				reservation_id: null,
				pl_adjustment_import_id: row.plAdjustmentImportId,
				amount: row.amount,
				status: null
			})
		}
		const invoiceRequest: ArInvoiceRequest = {
			invoice_date: this.invoiceDate,
			ar_vendor_id: +this.selectedArVendorId,
			lines: lines
		};

		this.accountsReceivableService.createArInvoice(invoiceRequest)
			.subscribe({
				next: (response) => {
					this.createdInvoice = response.invoice;
					if (this.totalAmountPaid !== 0) {
						this.createPaymentFromPreview(response.invoice.arInvoiceId);
					} else {
						this.loader.isLoading = false;
						this.router.navigate(['/admin/ar-invoice-detail/' + response.invoice.arInvoiceId]);
					}
				},
				error: error => {
					this.loader.isLoading = false;
					console.log(error);
				}
			})
	}

	createPaymentFromPreview(invoiceId: number) {
		const lines: ArPaymentRequestLine[] = [];
		for (const reservation of this.selectedReservations) {
			lines.push({
				ar_invoice_id: invoiceId,
				reservation_id: reservation.id,
				pl_adjustment_import_id: null,
				amount: reservation.amountNewPayment
			})
		}
		const miscRevenuePayments = this.miscBookingRevenueResult.map(revenueResult => ({
			...revenueResult,
			amountPayment: this.parsedData.find(row => row.confCode === revenueResult.confirmNum).amountNewPayment
		}))
		for (const row of miscRevenuePayments) {
			lines.push({
				ar_invoice_id: invoiceId,
				reservation_id: null,
				pl_adjustment_import_id: row.plAdjustmentImportId,
				amount: row.amountPayment
			})
		}

		if (this.bankChargeAmount > 0 && this.bankChargesResult !== undefined) {
			lines.push({
				ar_invoice_id: invoiceId,
				reservation_id: null,
				pl_adjustment_import_id: this.bankChargesResult.plAdjustmentImportId,
				amount: -this.bankChargesResult.amount,
			})
		}

		const paymentRequest = {
			ar_vendor_id: +this.selectedArVendorId,
			payment_date: this.paymentDate,
			total_amount: this.totalAmountPaid,
			lines: lines
		}

		this.accountsReceivableService.createArPayment(paymentRequest)
		.subscribe((statusCode) => {
			console.log('HTTP Status Code:', statusCode)
			this.loader.isLoading = false;
			if (statusCode === 200) {
				console.log('Payment was created');
				this.router.navigate(['/admin/ar-invoice-detail/' + invoiceId]);
			}
		}, (error) => {
			this.loader.isLoading = false;
			console.log(error);
		});
	}

	updateTotalInvoiceAmount() {
		const totalInvoiceReservations = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.amountNewInvoice, 0);

		const totalInvoiceMiscRevenue = this.notFoundReservationsReportRows.reduce(
			(total, row) => total + row.amountNewInvoice, 0);

		this.totalAmountInvoiced = totalInvoiceReservations + totalInvoiceMiscRevenue;
	}

	updateTotalPaymentAmount() {
		const totalPaymentReservations = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.amountNewPayment, 0
		)

		const totalPaymentMiscRevenue = this.notFoundReservationsReportRows.reduce(
			(total, row) => total + row.amountNewPayment, 0
		)

		this.totalAmountPaid = totalPaymentReservations + totalPaymentMiscRevenue - this.bankChargeAmount;
	}

	initializeData() {
		this.previewMode = 'validate';
		this.selectedArVendorId = '';
		this.bankChargesAmountInput = '$0.00';
		this.parsedData = [];
		this.dataSourceFound.data = [];
		this.selectedReservations = [];
		this.totalAmountInvoiced = 0;
		this.totalAmountPaid = 0;
		this.invoiceDate = undefined;
		this.paymentDate = undefined;
	}

	onBackButtonPressed() {
		this.detailsVisible = false;
		this.initializeData();
	}

	get showFileSelector() {
		return this.selectedArVendorId !== '' && this.invoiceDate !== undefined && this.paymentDate !== undefined;
	}

	get isCreatePreviewEnabled() {
		return this.selectedArVendorId !== '' && this.duplicateReservations.length === 0 && this.validationErrors.length === 0;
	}

	get isCreateInvoiceEnabled() {
		return this.createButtonEnabled || this.selectedReservations.length > 0 || this.invoiceDate !== undefined
			|| this.paymentDate !== undefined;
	}

	get bankChargeAmount() {
		return parseFloat(this.bankChargesAmountInput.replace(/[^0-9.-]+/g, ''));
	}

	findVendorById(id: string): ArVendor | undefined {
		return this.arVendors.find(vendor => vendor.id === +id);
	}

	// file select and drag & drop
	onDragOver(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(true);
	}

	onDragLeave(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(false);
	}

	onDrop(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.setDragAreaStyle(false);

		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			const file = event.dataTransfer.files[0];

			if (file.type === 'text/csv') {
				this.readFile(file);
			} else {
				alert('Please drop a valid CSV file.');
			}
		} else {
			alert('No file detected. Please drop a valid file.');
		}
	}

	onFileSelect(event: Event): void {
		const input = event.target as HTMLInputElement;
		const file = input.files && input.files[0];
		if (file) {
			this.readFile(file);
		}
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	private setDragAreaStyle(isDragging: boolean): void {
		const dragArea = document.querySelector('.drag-drop-area') as HTMLElement;
		if (isDragging) {
			dragArea.classList.add('dragging');
		} else {
			dragArea.classList.remove('dragging');
		}
	}

	private readFile(file: File): void {
		const reader = new FileReader();
		reader.onload = () => {
			const csvData = reader.result as string;
			this.fileContent = csvData;
			this.parsedData = this.parseCSV(csvData);
			this.validateStatusReport();
		};
		reader.onerror = () => {
			console.error('Error reading file');
		};
		reader.readAsText(file);
	}

	private parseCSV(csvData: string): ArCommissionStatusReport[] {
		const lines = csvData.split('\n').map(line => line.trim());
		const data = lines.slice(1);

		return data
		.filter(line => line)
		.map(line => {
			const values = line.split(',');

			return {
				confCode: values[0] ? values[0].trim() : '',
				reasonCode: values[1] ? values[1].trim() : '',
				amountNewInvoice: values[2] ? parseFloat(values[2].trim()) : 0,
				amountNewPayment: values[3] ? parseFloat(values[3].trim()) : 0,
			};
		});
	}
}
