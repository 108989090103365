// global imports
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, MatSort} from '@angular/material';
import {ConfirmationComponent} from '../../_components/confirmation-modal/confirmation.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Loader} from '../../_models/loader';
// local imports
import {ReservationService} from '../../_services/reservation-service';
import {PaymentDetailService} from '../../_services/payment-detail-service';
import {AuthService} from '../../_services/auth.service';
import {ReservationDetailResponse} from "../../_models/reservation";

@Component({
	selector: 'app-reservation-detail',
	templateUrl: './reservation-detail.component.html'
})

export class ReservationDetailComponent implements OnInit {
	loader: Loader;
	reservation: ReservationDetailResponse;
	payments;
	id: string;
	isAllowedRefund: boolean;
	totalAmountPaid: number;
	protected readonly Date = Date;

	@ViewChild(MatSort, {static: false}) sort: MatSort;
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	constructor(public snackBar: MatSnackBar,
				private router: Router,
				public dialog: MatDialog,
				private _reservationService: ReservationService,
				private paymentDetailService: PaymentDetailService,
				private authService: AuthService,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.id = this.route.snapshot.paramMap.get('id');
		this.loader = new Loader();
		this.fetch();
		this.isAllowedRefund = this.authService.isAllowedRefund();
	}

	fetch(): void {
		this.loader.isLoading = true;
		this._reservationService.getReservationDetailsById(this.id)
			.subscribe({
				next: res => {
					this.reservation = res;
					this.paymentDetailService.getPaymentDetails(this.reservation.uuid)
						.subscribe(
							res1 => {
								this.payments = res1;
								let totalAmount = 0;
								if (this.payments != null && this.payments.length > 0) {
									for (const p of this.payments) {
										totalAmount += p.amount;
									}
								}
								this.loader.isLoading = false;
								this.totalAmountPaid = totalAmount;
							}, err1 => {
								this.openSnackBar('Error while fetching payments', 'error');
								this.loader.isLoading = false;
							}
						)
				}, error: err => {
					this.openSnackBar('unknown server error occurred', 'error');
					this.loader.isLoading = false;
				}
			});
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	toDate(milli: number): string {
		return new Date(milli).toDateString() + ' ' + new Date(milli).toLocaleTimeString();
	}

	cancel(): void {
		const dialogRef = this.dialog.open(ConfirmationComponent, {data: 'Are you sure you want to cancel this reservation?'});

		dialogRef.afterClosed().subscribe(confirmed => {
			if (confirmed) {
				this._reservationService.cancelReservation(this.reservation.uuid)
					.subscribe({
						next: res => {
							console.log(res);
							this.openSnackBar('reservation is cancelled', 'success');
							this.fetch();
						}, error: err => {
							const genericError = "Cancellation failed, please check the server logs, timestamp: " + new Date().toLocaleString();

							try {
								let errMessage = (err.error.errorMessage == null ? genericError : err.error.errorMessage) + '. ' + genericError;
								console.log(errMessage);
								this.openSnackBar(errMessage, 'error');
							}
							catch (e) {
								console.log(genericError);
								this.openSnackBar(genericError, 'error');
							}
						}
					});
			}
		});
	}

	cancelArdb(): void {
		const dialogRef = this.dialog.open(ConfirmationComponent, {data: 'Are you sure you want to cancel this reservation in ARDB only?'});
		const param = {'partner_cancel': false};

		dialogRef.afterClosed().subscribe(confirmed => {
			if (confirmed) {
				this._reservationService.cancelReservationArdb(param, this.reservation.uuid)
					.subscribe({
						next: res => {
							console.log(res);
							this.openSnackBar('reservation is cancelled in ARDB', 'success');
							this.fetch();
						}, error: err => {
							this.openSnackBar('unknown server error occurred', 'error');
						}
					});
			}
		});
	}

	refund(): void {
		const dialogRef = this.dialog.open(ConfirmationComponent, {data: 'Are you sure you want to refund this reservation?'});

		dialogRef.afterClosed().subscribe(confirmed => {
			if (confirmed) {
				this._reservationService.refundReservation(this.reservation.uuid)
					.subscribe({
						next: res => {
							this.openSnackBar('reservation refunded successfully', 'success');
							this.fetch();
						}, error: err => {
							this.openSnackBar(err.error.errorMessage || 'unknown server error occurred', 'error');
						}
					});
			}
		});
	}

	sendConfirmationEmail(): void {
		this._reservationService.sendConfirmationEmail(this.reservation.uuid)
			.subscribe({
				next: res => {
					this.openSnackBar('Confirmation email has been added to the queue', 'success');
				}, error: err => {
					this.openSnackBar('unknown server error occurred', 'error');
				}
			});
	}

	sendSurveyEmail(): void {
		this._reservationService.sendSurveyEmail(this.reservation.uuid)
			.subscribe({
				next: res => {
					this.openSnackBar('Survey email has been added to the queue ', 'success');
				}, error: err => {
					this.openSnackBar('unknown server error occurred', 'error');
				}
			});
	}

}
