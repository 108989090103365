import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

import moment from 'moment';

import {AccountsPayableService} from '../../_services/accounts-payable.service';
import {
	ApInvoiceRequest,
	ApInvoiceRequestLine,
	ApInvoiceResponse,
	ApPaymentRequest,
	ApPaymentRequestLine,
	ApVendor,
	InvoiceDetailsReservation,
} from '../../_models/accounts-payable.model';
import {Loader} from '../../_models/loader';


@Component({
	selector: 'app-ap-invoice-create',
	templateUrl: './ap-invoice-create.component.html',
	styleUrls: ['./ap-invoice-create.component.scss']
})
export class ApInvoiceCreateComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort, {static: false}) set contentMain(sort: MatSort) {
		this.dataSourceMain.sort = sort;
	}

	@ViewChild(MatSort, {static: false}) set contentPreview(sort: MatSort) {
		this.dataSourcePreview.sort = sort;
	}

	dataSourceMain = new MatTableDataSource<ApInvoiceResponse>();
	dataSourcePreview = new MatTableDataSource<InvoiceDetailsReservation>();
	selectedReservations: InvoiceDetailsReservation[] = [];
	loader = new Loader();
	presentPreview = false
	apVendors: ApVendor[] = [];
	_selectedApVendorId = '';
	selectedApVendor: ApVendor;
	selectedApInvoiceRequestId: number;
	invoiceTotal = 0;
	invoiceDate = new Date();
	paymentDate: Date;

	displayedColumnsMain: string[] = ['id', 'invoiceDate', 'apVendorId', 'apVendorName', 'totalAmount', 'status'];

	displayedColumnsPreview: string[] = ['reservationId', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'amountInvoiced'];

	constructor(private readonly accountsPayableService: AccountsPayableService,
				         private readonly router: Router) {
	}

	ngOnInit(): void {
		console.log('ngOnInit');
		this.paymentDate = this.calculateLastDayOfMonth(this.invoiceDate);
	}

	ngAfterViewInit(): void {
		console.log('ngAfterViewInit');

		this.accountsPayableService.getAllApVendors().subscribe({
			next: data => {
				this.apVendors = data;
			}
		})
	}

	get selectedApVendorId() {
		return this._selectedApVendorId;
	}

	set selectedApVendorId(value: string) {
		if (this._selectedApVendorId !== value) {
			this._selectedApVendorId = value;

			console.log('vendor selected ' + this.selectedApVendorId);

			this.accountsPayableService.getAllApInvoices(true).subscribe({
				next: invoiceRequests => {
					this.dataSourceMain.data = invoiceRequests.filter(i => i.status === 'PENDING' && i.apVendorId === +this._selectedApVendorId)
				}
			})
		}
	}

	findVendorById(id: string): ApVendor | undefined {
		return this.apVendors.find(vendor => vendor.id === +id);
	}

	updateInvoiceTotal() {
		this.invoiceTotal = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.amountInvoiced, 0);
	}

	createPreviewFromSelection() {
		console.log('selected ap vendor: ' + this.selectedApVendorId);
		this.selectedApVendor = this.findVendorById(this.selectedApVendorId);
		this.presentPreview = true;
		this.dataSourcePreview.data = this.selectedReservations;
	}

	onBackButtonPressed() {
		this.presentPreview = false;
	}

	createInvoiceFromPreview() {
		console.log('createInvoiceFromPreview');
		this.loader.isLoading = true;

		const lines: ApInvoiceRequestLine[] = [];
		for (const reservation of this.selectedReservations) {
			lines.push({
				reservation_id: reservation.reservationId,
				amount: reservation.amountInvoiced,
				campaign_id: '11006'
			})
		}
		const invoiceRequest: ApInvoiceRequest = {
			invoice_date: this.invoiceDate,
			invoice_due_date: null,
			ap_vendor_id: +this.selectedApVendorId,
			ap_invoice_request_id: this.selectedApInvoiceRequestId,
			lines: lines
		};

		this.accountsPayableService.createApInvoice(false, invoiceRequest)
		.subscribe((response) => {
			console.log('HTTP Status Code:', response.statusCode)
			if (response.statusCode === 200) {
				const apInvoiceId = response.invoice.apInvoiceId;
				console.log('Invoice was created');
				this.createPaymentForInvoice(apInvoiceId);
			}
		},         (error) => {
			console.log(error);
			this.loader.isLoading = false;
		});
	}

	createPaymentForInvoice(apInvoiceId: number) {
		const lines: ApPaymentRequestLine[] = [];
		for (const reservation of this.selectedReservations) {
			lines.push({
				ap_invoice_id: apInvoiceId,
				reservation_id: reservation.reservationId,
				pl_adjustment_import_id: null,
				campaign_id: '11006',
				dropoff_date: moment(reservation.dropoffDate).format('YYYY-MM-DDTHH:mm:ss'),
				amount: reservation.amountInvoiced
			})
		}

		const paymentRequest: ApPaymentRequest = {
			ap_vendor_id: +this.selectedApVendorId,
			payment_date: moment(this.paymentDate).format('YYYY-MM-DD'),
			total_amount: this.selectedReservations.reduce(
				(total, reservation) => total + reservation.amountInvoiced, 0),
			lines: lines
		}

		this.accountsPayableService.createApPayment(paymentRequest).subscribe({
			next: value => {
				this.loader.isLoading = false;
				this.router.navigate([`/admin/ap-invoice-list`]);
			},
			error: err => {
				console.log(err);
				this.loader.isLoading = false;
			}
		})
	}

	onRowClicked(row) {
		console.log('row clicked', row)
		this.selectedApInvoiceRequestId = row.id;
		this.accountsPayableService.getInvoiceDetails(true, row.id).subscribe({
			next: value => {
				console.log('Invoice details: ', value)
				this.selectedReservations = value.reservations;
				this.dataSourcePreview.data = this.selectedReservations;

				this.updateInvoiceTotal();
				this.createPreviewFromSelection();
			},
			error: err => {
				console.log(err)
			}
		})
	}

	onInvoiceDateChange() {
		console.log('onDateChange');
		this.paymentDate = this.calculateLastDayOfMonth(this.invoiceDate);
	}

	calculateLastDayOfMonth(date: Date): Date {
		return new Date(date.getFullYear(), date.getMonth() + 1, 0);
	}
}
