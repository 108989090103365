import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSort, MatTableDataSource} from '@angular/material';

import {Loader} from '../../_models/loader';
import {AccountsPayableService} from '../../_services/accounts-payable.service';
import {
	InvoiceDetails,
	InvoiceDetailsPayment,
	InvoiceDetailsReservation
} from '../../_models/accounts-payable.model';


@Component({
	selector: 'app-ap-invoice-detail',
	templateUrl: './ap-invoice-detail.component.html',
	styleUrls: ['./ap-invoice-detail.component.scss']
})
export class ApInvoiceDetailComponent implements OnInit, AfterViewInit {

	dataSourceReservations = new MatTableDataSource<InvoiceDetailsReservation>();
	dataSourcePayments = new MatTableDataSource<InvoiceDetailsPayment>();
	loader = new Loader();
	invoiceId = 0;
	invoiceDetail: InvoiceDetails = {
		id: 0,
		apVendorName: '',
		apVendorId: 1,
		apInvoiceRequestId: 0,
		invoiceDate: new Date(),
		invoiceDueDate: null,
		amountPaid: 0,
		amountTotal: 0,
		bankCharges: 0,
		reservations: [],
		payments: [],
		status: ''
	}

	@ViewChild(MatSort, {static: false}) set contentReservations(sort: MatSort) {
		this.dataSourceReservations.sort = sort;
	}

	@ViewChild(MatSort, {static: false}) set contentPayments(sort: MatSort) {
		this.dataSourcePayments.sort = sort;
	}

	displayedColumnsReservations: string[] = ['reservationId', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'amountInvoiced', 'amountPaid', 'amountUnpaid'];

	displayedColumnsPayments: string[] = ['id', 'apVendorId', 'paymentDate', 'totalAmount'];

	constructor(private readonly accountsPayableService: AccountsPayableService,
	            private readonly router: Router,
	            private readonly route: ActivatedRoute,
	            private readonly location: Location) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			const id = params.get('id');
			this.invoiceId = id ? +id : null;
			console.log('Invoice ID:', this.invoiceId);
		});
		this.loader.isLoading = false;
	}

	ngAfterViewInit() {
		console.log('afterViewInit');
		this.accountsPayableService.getInvoiceDetails(false, this.invoiceId).subscribe({
			next: invoice => {
				this.loader.isLoading = false;
				this.invoiceDetail = invoice;
				this.dataSourceReservations.data = invoice.reservations;
				this.dataSourcePayments.data = invoice.payments;
			}
		})
	}

	onBackButtonPressed() {
		this.location.back()
	}
}
