import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
	DateAdapter, MAT_DATE_LOCALE
} from '@angular/material/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Platform} from '@angular/cdk/platform';

import {MonthpickerDateAdapter} from './monthpicker-date-formats';

@Component({
	selector: 'app-monthpicker',
	templateUrl: './monthpicker.component.html',
	styleUrls: ['./monthpicker.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MonthpickerDateAdapter,
			deps: [MAT_DATE_LOCALE, Platform],
		},
	],
})
export class MonthpickerComponent {
	@Input() public monthAndYear: Date | null = null;
	@Input() public label: string;
	@Output() public monthAndYearChange = new EventEmitter<Date | null>();

	public emitDateChange(event: MatDatepickerInputEvent<Date | null>): void {
		this.monthAndYear = event.value;
		this.monthAndYearChange.emit(event.value);
	}

	public monthChanged(value: any, widget: any): void {
		this.monthAndYear = value;
		this.monthAndYearChange.emit(value);
		widget.close();
	}
}
