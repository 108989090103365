import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountsReceivableService} from '../../_services/accounts-receivable.service';
import {MatTableDataSource} from '@angular/material/table';
import {
	ArPaymentRequestLine,
	InvoiceDetails,
	InvoiceDetailsMiscRevenue,
	InvoiceDetailsReservation
} from '../../_models/accounts-receivable.model';
import {MatSort} from '@angular/material/sort';
import {Loader} from '../../_models/loader';


@Component({
	selector: 'app-ar-payment-create',
	templateUrl: './ar-payment-create.component.html',
	styleUrls: ['./ar-payment-create.component.scss']
})
export class ArPaymentCreateComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort, {static: false}) set contentMain(sort: MatSort) {
		this.dataSourceMain.sort = sort;
	}

	@ViewChild(MatSort, {static: false}) set contentPreview(sort: MatSort) {
		this.dataSourcePreview.sort = sort;
	}

	dataSourceMain = new MatTableDataSource<InvoiceDetailsReservation>();
	dataSourceMiscRevenues = new MatTableDataSource<InvoiceDetailsMiscRevenue>();
	dataSourcePreview = new MatTableDataSource<InvoiceDetailsReservation>();
	dataSourceMiscRevenuesPreview = new MatTableDataSource<InvoiceDetailsMiscRevenue>();
	loader = new Loader();
	presentPreview = false;
	paymentAmount = 0;
	paymentDate: Date;
	invoiceId = 0;
	invoiceDetail: InvoiceDetails = {
		id: 0,
		arVendorName: '',
		arVendorId: 1,
		invoiceDate: new Date(),
		amountPaid: 0,
		amountTotal: 0,
		bankCharges: 0,
		reservations: [],
		miscRevenues: [],
		payments: []
	}
	selectedReservations: InvoiceDetailsReservation[] = [];
	selectedMiscRevenues: InvoiceDetailsMiscRevenue[] = [];

	displayedColumnsMain: string[] = ['select', 'reservationId', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'amountInvoiced', 'amountPaid', 'amountUnpaid'];

	displayedColumnsMiscRevenues: string[] = ['select', 'confirmNum', 'plAdjustmentImportId', 'amountInvoiced', 'amountPaid', 'amountUnpaid'];

	displayedColumnsPreview: string[] = ['reservationId', 'brandId', 'brandName', 'confirmationCode', 'reservationDate',
		'pickupDate', 'dropoffDate', 'pretaxCost', 'totalCost', 'amountInvoiced', 'amountPaid', 'amountUnpaid'];

	displayedColumnsMiscRevenuesPreview: string[] = ['confirmNum', 'plAdjustmentImportId', 'amountInvoiced', 'amountPaid', 'amountUnpaid'];

	selectAllChecked = false;
	selectAllMiscRevenues = false;

	constructor(private readonly accountsReceivableService: AccountsReceivableService,
	            private readonly route: ActivatedRoute,
	            private readonly router: Router) {
	}

	ngOnInit() {
		this.loader.isLoading = false;
		const now = new Date();
		this.paymentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		this.route.paramMap.subscribe((params) => {
			const id = params.get('id');
			this.invoiceId = id ? +id : null;
			console.log('Create Payment Invoice ID:', this.invoiceId);
		});
	}

	ngAfterViewInit() {
		this.accountsReceivableService.getInvoiceDetails(this.invoiceId).subscribe({
			next: invoice => {
				this.invoiceDetail = invoice;
				this.dataSourceMain.data = invoice.reservations.filter(reservation => reservation.amountPaid !== reservation.amountInvoiced);
				this.dataSourceMiscRevenues.data = invoice.miscRevenues.filter(revenue => revenue.amountUnpaid > 0);
			}
		})
	}

	toggleSelectAll() {
		this.toggleSelectedReservations();
		this.toggleSelectAllMiscRevenues();
	}

	toggleSelectedReservations() {
		this.selectAllChecked = !this.selectAllChecked;
		const filteredData = this.dataSourceMain.filteredData;
		filteredData.forEach(row => row.selected = this.selectAllChecked);
		this.updateSelectedReservations();
	}

	toggleSelectAllMiscRevenues() {
		this.selectAllMiscRevenues = !this.selectAllMiscRevenues;
		const filteredData = this.dataSourceMiscRevenues.filteredData;
		filteredData.forEach(row => row.selected = this.selectAllMiscRevenues);
		this.updateSelectedMiscRevenue();
	}

	createPreviewFromSelection() {
		console.log('create payment preview - payment date: ' + this.paymentDate + ', amount: ' + this.paymentAmount);
		this.dataSourcePreview.data = this.selectedReservations;
		this.dataSourceMiscRevenuesPreview.data = this.selectedMiscRevenues;
		this.presentPreview = true;
	}

	get isCreatePreviewDisabled(): boolean {
		return this.dataSourceMain.data.filter(res => res.selected).length === 0;
	}

	roundToTwoDecimals(): void {
		if (this.paymentAmount !== null) {
			this.paymentAmount = parseFloat(this.paymentAmount.toFixed(2));
		}
	}

	updateSelectedReservations() {
		this.selectedReservations = this.dataSourceMain.data.filter(res => res.selected);
		console.log('selected count: ' + this.selectedReservations.length);
		this.updatedPaymentTotal();
		this.roundToTwoDecimals();
	}

	updateSelectedMiscRevenue() {
		this.selectedMiscRevenues = this.dataSourceMiscRevenues.data.filter(rev => rev.selected);
		this.updatedPaymentTotal();
		this.roundToTwoDecimals();
	}

	updatedPaymentTotal() {
		const paymentReservations = this.selectedReservations.reduce(
			(total, reservation) => total + reservation.amountUnpaid, 0);

		const paymentMisRevenue = this.selectedMiscRevenues.reduce(
			(total, revenue) => total + revenue.amountUnpaid, 0
		)

		this.paymentAmount = paymentReservations + paymentMisRevenue;
	}

	createPaymentFromPreview() {
		this.loader.isLoading = true;
		const lines: ArPaymentRequestLine[] = [];
		for (const reservation of this.selectedReservations) {
			lines.push({
				ar_invoice_id: this.invoiceId,
				reservation_id: reservation.reservationId,
				pl_adjustment_import_id: null,
				amount: reservation.amountUnpaid
			})
		}

		for (const revenue of this.selectedMiscRevenues) {
			lines.push({
				ar_invoice_id: this.invoiceId,
				reservation_id: null,
				pl_adjustment_import_id: revenue.plAdjustmentImportId,
				amount: revenue.amountUnpaid
			})
		}

		const paymentRequest = {
			ar_vendor_id: this.invoiceDetail.arVendorId,
			payment_date: this.paymentDate,
			total_amount: this.paymentAmount,
			lines: lines
		}

		this.accountsReceivableService.createArPayment(paymentRequest)
		.subscribe((statusCode) => {
			console.log('HTTP Status Code:', statusCode)
			this.loader.isLoading = false;
			if (statusCode === 200) {
				console.log('Payment was created');
				this.router.navigate(['/admin/ar-invoice-detail/' + this.invoiceId]);
			}
		}, (error) => {
			console.log(error);
		});
	}

	onBackButtonPressed() {
		this.presentPreview = false;
	}
}
