import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TemplateComponent} from './admin/template-boiler/template.component';

import {AuthGuard} from './_guards/auth-guard-service';
import {LoginComponent} from './_components/login/login.component';
import {BrandPositionComponent} from './admin/brand-tool/brand-pos.component';
import {BrandEditComponent} from './admin/brand-tool/brand-edit.component';
import {BrandCpcEditorComponent} from './admin/brand-tool/brand-cpc-editor.component';
import {ExpenseAdjustmentComponent} from './admin/expense-adjustment/expense-adjustment.component'
import {HomeComponent} from './home/home.component'
import {VendorEditComponent} from './admin/vendor-tool/vendor-edit.component';
import {SessionDebugComponent} from './admin/session-debug/session-debug.component';
import {AdCostAdjustmentComponent} from './admin/ad-adjustment/ad-adjustment.component';
import {AbToolComponent} from './admin/ab-tool/ab-tool.component';
import {ChangePasswordComponent} from './admin/account-setting/change-password.component';
import {EmailServiceComponent} from './admin/email-service-tool/email-service.component';
import {CampaignToolComponent} from './admin/campaign-tool/campaign-tool.component';
import {LocationUpdateComponent} from './admin/location-update/location-update.component';
import {PartnerLocationUpdateComponent} from './admin/partner-location-update/location-update.component';
import {SeoToolComponent} from './admin/seo-tool/seo-tool.component';
import {ReservationToolComponent} from './admin/reservation-tool/reservation-tool.component';
import {ReservationDetailComponent} from './admin/reservation-tool/reservation-detail.component';
import {EmailSubscriptionComponent} from './admin/email-subscription/email-subscription.component';
import {DealSelectionComponent} from './admin/deal-selection/deal-selection.component';
import {PartnerToolComponent} from './admin/partner-tool/partner-tool.component';
import {KlaviyoToolComponent} from './admin/klaviyo-tool/klaviyo-tool.component';
import {CampaignGroupComponent} from './admin/campaign-group-tool/campaign-group.component';
import {AdPlacementComponent} from './admin/ad-placement-tool/ad-placement-tool.component';
import {SeoCarComponent} from './admin/seo-car-class/seo-car.component';
import {DashboardRequestComponent} from './admin/dashboard-request/dashboard-request.component';
import {BrandCpcEditDialogComponent} from './admin/brand-tool/brand-cpc-edit-dialog.component';
import {SeoCarEditComponent} from './admin/seo-car-class/seo-car-edit.component';
import {BrandDetailsEditComponent} from './admin/brand-tool/brand-details-edit.component';
import {VendorDetailsEditComponent} from './admin/vendor-tool/vendor-details-edit.component';
import {InsuranceCommissionComponent} from './admin/insurance-commission-tool/insurance-commission';
import {InsuranceCommissionEditComponent} from './admin/insurance-commission-tool/insurance-commission-edit.component';
import {PartnerSupplierMappingComponent} from './admin/partner-supplier-mapping-tool/supplier-mapping.component';
import {MarketingLandingPageComponent} from './admin/marketing-landing-page-tool/marketing-landing-page.component';
import {MarketingLandingPageDetailsEditComponent} from './admin/marketing-landing-page-tool/marketing-landing-page-details-edit.component';
import {
	MarketingLandingPageDetailsCreateComponent
} from './admin/marketing-landing-page-tool/marketing-landing-page-details-create.component';
import {BrandOnboardingRequestComponent} from './admin/brand-onboarding-request/brand-onboarding-request.component';
import {ArInvoiceCreateComponent} from './admin/ar-invoice-create/ar-invoice-create.component';
import {ArInvoiceListComponent} from './admin/ar-invoice-list/ar-invoice-list.component';
import {ArInvoiceDetailComponent} from './admin/ar-invoice-detail/ar-invoice-detail.component';
import {ArPaymentCreateComponent} from './admin/ar-payment-create/ar-payment-create.component';
import {
	ArReservationReportCreateComponent
} from './admin/ar-reservation-report-create/ar-reservation-report-create.component';
import {ApInvoiceListComponent} from './admin/ap-invoice-list/ap-invoice-list.component';
import {
	ArReservationReportCreateOurNumbersComponent
} from './admin/ar-reservation-report-create-our-numbers/ar-reservation-report-create-our-numbers.component';
import {ApInvoiceCreateComponent} from './admin/ap-invoice-create/ap-invoice-create.component';
import {ApInvoiceRequestListComponent} from './admin/ap-invoice-request-list/ap-invoice-request-list.component';
import {ApInvoiceRequestCreateComponent} from './admin/ap-invoice-request-create/ap-invoice-request-create.component';
import {ApInvoiceDetailComponent} from './admin/ap-invoice-detail/ap-invoice-detail.component';
import {ApInvoiceRequestDetailComponent} from './admin/ap-invoice-request-detail/ap-invoice-request-detail.component';


const appRoutes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		component: HomeComponent
	}, {
		path: 'login',
		component: LoginComponent
	}, {
		path: 'admin/brandPos',
		canActivate: [AuthGuard],
		component: BrandPositionComponent
	}, {
		path: 'admin/brandEdit',
		canActivate: [AuthGuard],
		component: BrandEditComponent
	}, {
		path: 'admin/brandEdit/edit/:id',
		component: BrandDetailsEditComponent,
		canActivate: [AuthGuard],
	}, {
		path: 'admin/vendorEdit',
		canActivate: [AuthGuard],
		component: VendorEditComponent
	}, {
		path: 'admin/vendorEdit/edit/:id',
		component: VendorDetailsEditComponent,
		canActivate: [AuthGuard],
	}, {
		path: 'admin/expense',
		canActivate: [AuthGuard],
		component: ExpenseAdjustmentComponent
	},
	{
		path: 'admin/insuranceAdjustment',
		canActivate: [AuthGuard],
		component: InsuranceCommissionComponent
	}, {
		path: 'admin/insuranceAdjustment/edit/:id',
		component: InsuranceCommissionEditComponent,
		canActivate: [AuthGuard],
	}, {
		path: 'admin/landingPage/edit/:id',
		component: MarketingLandingPageDetailsEditComponent,
		canActivate: [AuthGuard],
	}, {
		path: 'admin/landingPage/create',
		component: MarketingLandingPageDetailsCreateComponent,
		canActivate: [AuthGuard],
	}, {
		path: 'admin/sessionDebug',
		canActivate: [AuthGuard],
		component: SessionDebugComponent
	}, {
		path: 'admin/adAdjust',
		canActivate: [AuthGuard],
		component: AdCostAdjustmentComponent
	}, {
		path: 'admin/dealSelection',
		canActivate: [AuthGuard],
		component: DealSelectionComponent
	}, {
		path: 'admin/abTool',
		canActivate: [AuthGuard],
		component: AbToolComponent
	}, {
		path: 'password',
		canActivate: [AuthGuard],
		component: ChangePasswordComponent
	}, {
		path: 'admin/emailService',
		canActivate: [AuthGuard],
		component: EmailServiceComponent
	}, {
		path: 'admin/brandOnboardingRequest',
		canActivate: [AuthGuard],
		component: BrandOnboardingRequestComponent
	}, {
		path: 'admin/campaign',
		canActivate: [AuthGuard],
		component: CampaignToolComponent
	}, {
		path: 'admin/campaignGroup',
		canActivate: [AuthGuard],
		component: CampaignGroupComponent
	}, {
		path: 'admin/update_cpc',
		canActivate: [AuthGuard],
		component: BrandCpcEditorComponent,
	}, {
		path: 'admin/update_cpc/edit/:id',
		component: BrandCpcEditDialogComponent,
		canActivate: [AuthGuard],
	}, {
		path: 'admin/locationUpdate',
		canActivate: [AuthGuard],
		component: LocationUpdateComponent
	}, {
		path: 'admin/partnerLocationUpdate',
		canActivate: [AuthGuard],
		component: PartnerLocationUpdateComponent
	}, {
		path: 'admin/seoLocation',
		canActivate: [AuthGuard],
		component: SeoToolComponent
	}, {
		path: 'admin/landingPage',
		canActivate: [AuthGuard],
		component: MarketingLandingPageComponent
	}, {
		path: 'admin/reservations',
		canActivate: [AuthGuard],
		component: ReservationToolComponent
	}, {
		path: 'admin/reservations/detail/:id',
		canActivate: [AuthGuard],
		component: ReservationDetailComponent
	}, {
		path: 'admin/emailSubscirption',
		canActivate: [AuthGuard],
		component: EmailSubscriptionComponent
	}, {
		path: 'admin/partnerSupplierMapping',
		canActivate: [AuthGuard],
		component: PartnerSupplierMappingComponent
	}, {
		path: 'admin/partner',
		canActivate: [AuthGuard],
		component: PartnerToolComponent
	}, {
		path: 'admin/klaviyo',
		canActivate: [AuthGuard],
		component: KlaviyoToolComponent
	}, {
		path: 'admin/adPlacements',
		canActivate: [AuthGuard],
		component: AdPlacementComponent
	}, {
		path: 'admin/seo-car-class',
		canActivate: [AuthGuard],
		component: SeoCarComponent
	}, {
		path: 'admin/seo-car-class/edit/:id',
		component: SeoCarEditComponent,
		canActivate: [AuthGuard],
	}, {
		path: 'admin/template',
		canActivate: [AuthGuard],
		component: TemplateComponent
	}, {
		path: 'admin/dashboard-request',
		canActivate: [AuthGuard],
		component: DashboardRequestComponent
	}, {
		path: 'admin/ar-invoice-create',
		canActivate: [AuthGuard],
		component: ArInvoiceCreateComponent
	},
	{
		path: 'admin/ar-invoice-list',
		canActivate: [AuthGuard],
		component: ArInvoiceListComponent
	},
	{
		path: 'admin/ar-invoice-detail/:id',
		canActivate: [AuthGuard],
		component: ArInvoiceDetailComponent
	},
	{
		path: 'admin/invoices/:id/ar-payment-create',
		canActivate: [AuthGuard],
		component: ArPaymentCreateComponent
	},
	{
		path: 'admin/reservations/status-their-numbers',
		canActivate: [AuthGuard],
		component: ArReservationReportCreateComponent
	},
	{
		path: 'admin/reservations/status-our-numbers',
		canActivate: [AuthGuard],
		component: ArReservationReportCreateOurNumbersComponent
	},
	{
		path: 'admin/ap-invoice-list',
		canActivate: [AuthGuard],
		component: ApInvoiceListComponent
	},
	{
		path: 'admin/ap-invoice-detail/:id',
		canActivate: [AuthGuard],
		component: ApInvoiceDetailComponent
	},
	{
		path: 'admin/ap-invoice-create',
		canActivate: [AuthGuard],
		component: ApInvoiceCreateComponent
	},
	{
		path: 'admin/ap-invoice-request-create',
		canActivate: [AuthGuard],
		component: ApInvoiceRequestCreateComponent
	},
	{
		path: 'admin/ap-invoice-request-list',
		canActivate: [AuthGuard],
		component: ApInvoiceRequestListComponent
	},
	{
		path: 'admin/ap-invoice-request-detail/:id',
		canActivate: [AuthGuard],
		component: ApInvoiceRequestDetailComponent
	},
	{path: '**', redirectTo: ''}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule]
})
export class AppRoutingModule {

}
